<template>
  <Panel>
    <template #title><span>Add Operator</span></template>
    <template #content>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :label-position="labelPosition"
      >
        <el-form-item label="Firstname" prop="firstname" class="first-item">
          <el-input
            type="text"
            v-model="ruleForm.firstname"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="Lastname" prop="lastname">
          <el-input
            type="text"
            v-model="ruleForm.lastname"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="Email" prop="email">
          <el-input
            type="text"
            v-model="ruleForm.email"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="Password" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :disabled="flag"
            >Submit</el-button
          >
          <el-button @click="resetForm('ruleForm')">Reset</el-button>
        </el-form-item>
      </el-form>
    </template>
  </Panel>
</template>

<script>
import { validateEmail, validatePwd } from '@/utils/validate.js'

import { adduser } from '@/api/user'
export default {
  data() {
    var validatefirstname = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Enter your firstname.'))
      } else {
        callback()
      }
    }
    var validatelastname = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Enter your lastname.'))
      } else {
        callback()
      }
    }
    return {
      flag: false,
      labelPosition: 'right',
      ruleForm: {
        firstname: '',
        lastname: '',
        email: '',
        password: ''
      },
      rules: {
        firstname: [{ validator: validatefirstname, trigger: 'blur' }],
        lastname: [{ validator: validatelastname, trigger: 'blur' }],
        email: [{ validator: validateEmail, trigger: 'blur' }],
        password: [{ validator: validatePwd, trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (this.flag) {
          return
        }
        if (valid) {
          this.flag = true
          const data = await adduser(this.ruleForm)
          // console.log(data)
          if (data.status === 200) {
            this.$message({
              message: 'Add successful',
              type: 'success'
            })
            this.$router.push('/listcount')
            setTimeout(() => {
              this.flag = false
            }, 2000)
          } else {
            this.$message({
              message: data.data.toperr,
              type: 'error'
            })
            setTimeout(() => {
              this.flag = false
            }, 2000)
          }
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.first-item {
  margin-top: 20px;
}
.el-form-item {
  margin-bottom: 40px;
}
</style>
